import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TaskService from "../services/TaskService";

const Checklist = () => {
    const [parentTasks, setParentTasks] = useState([]);
    const [steps, setSteps] = useState({});
    const [subSteps, setSubSteps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState('before'); // Toggle state to switch between "Before" and "After" USA tasks
    const navigate = useNavigate();

    useEffect(() => {
        const taskService = new TaskService();

        const fetchTasks = async () => {
            try {
                const { parentTasks, steps, subSteps } = await taskService.processTasks();
                setParentTasks(parentTasks || []);
                setSteps(steps || {});
                setSubSteps(subSteps || {});
            } catch (error) {
                console.error('Error fetching and processing tasks:', error);
                setParentTasks([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTasks();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const toggleTaskCompletion = (id) => {
        setParentTasks(parentTasks.map(task =>
            task.taskId === id ? { ...task, completed: !task.completed } : task
        ));
    };

    const handleTaskClick = (taskId, taskName, videoUrl) => {
        const relatedSteps = steps[taskId] || [];
        const relatedSubSteps = relatedSteps.reduce((acc, step) => {
            acc[step.taskId] = subSteps[step.taskId] || [];
            return acc;
        }, {});
        console.log("Related subSteps:", relatedSubSteps);

        navigate(`/task/${taskId}`, { state: {taskName, videoUrl, steps: relatedSteps, subSteps: relatedSubSteps } });
    };

    const completedTasksCount = parentTasks.filter(task => task.completed).length;
    const totalTasks = parentTasks.length;
    const completionPercentage = Math.round((completedTasksCount / totalTasks) * 100);

    return (
        <div className="container mx-auto px-4 mt-24">
            <div className="isolate mt-44 mb-36 p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                {/* Background effect */}
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                    style={{ pointerEvents: 'none' }} // Disable interactions with the background
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#a7c7e7] to-[#b0e0e6] opacity-50 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="max-w-xl text-center mx-auto lg:max-w-2xl mb-12">
                    <h2 className="mb-6">
                        <span className="relative inline-block">
                            <span className="relative text-primary title">Your Checklist</span>
                        </span>
                    </h2>

                    {/* Toggle Button */}
                    <div className="flex justify-center mb-6">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() => setView('before')}
                                className={`py-2 px-4 text-sm font-medium text-white rounded-l-lg border border-blue-500 ${
                                    view === 'before' ? 'bg-blue-500' : 'bg-gray-300 text-gray-700'
                                } hover:bg-blue-600 focus:ring-2 focus:ring-blue-200`}
                            >
                                Before USA
                            </button>
                            <button
                                onClick={() => setView('after')}
                                className={`py-2 px-4 text-sm font-medium text-white rounded-r-lg border border-blue-500 ${
                                    view === 'after' ? 'bg-blue-500' : 'bg-gray-300 text-gray-700'
                                } hover:bg-blue-600 focus:ring-2 focus:ring-blue-200`}
                            >
                                After USA
                            </button>
                        </div>
                    </div>

                    <p className="mb-10 subdesc">
                        Stay organized with our comprehensive checklist to ensure you complete all essential tasks before your journey to the USA.
                    </p>
                    <div className="w-full bg-gray-200 rounded-full h-4 mb-6">
                        <div
                            className="bg-primary h-4 rounded-full"
                            style={{ width: `${completionPercentage}%` }}
                        ></div>
                    </div>
                    <p className="text-primary font-semibold">{completionPercentage}% Complete</p>
                </div>
                <div className="flex flex-wrap mx-auto md:max-w-xl lg:max-w-screen-xl">
                    {parentTasks.map(task => (
                        <div key={task.taskId} className="mt-10 px-2 w-1/2 lg:w-1/3">
                            <div
                                className={`relative mb-2 rounded-[20px] bg-white p-5 shadow-md md:px-2 xl:px-5 transition-all duration-300 transform ${
                                    task.completed ? 'opacity-50' : ''
                                }`}
                                onClick={() => handleTaskClick(task.taskId, task.taskName, task.videoUrl)}
                                style={{ cursor: 'pointer', height: '150px' }} // Decreased height for a more compact size
                            >
                                <h4 className="text-sm sm:text-base mb-2 font-semibold text-primary">
                                    {task.taskName}
                                </h4>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent click from affecting card navigation
                                        toggleTaskCompletion(task.taskId);
                                    }}
                                    className={`w-full mt-4 py-2 text-sm font-medium text-white rounded-full ${
                                        task.completed ? 'bg-red-500' : 'bg-primary'
                                    }`}
                                >
                                    {task.completed ? "Undo" : "Mark Complete"}
                                </button>
                            </div>
                            <style jsx>{`
                                div.relative:hover {
                                    background-color: #f0f8ff; /* Light blue color effect on hover */
                                    transform: scale(1.05); /* Pop effect on hover */
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12); /* Add shadow for focus effect */
                                    cursor: pointer;
                                }
                            `}</style>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Checklist;
