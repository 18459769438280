import axios from 'axios';

class UserService {
  // Method to send the Google sign-in token to the backend
  // Using 'this' to access instance properties
  static API_BASE_URL = 'https://stagingapi.prep4usa.com:8444/pfu-rest-api/api/v1';
  // static API_BASE_URL = 'http://localhost:8080/api/v1';
  
  static signInWithGoogle = async (idToken, user) =>{

    try {
      const response = await axios.post(`${this.API_BASE_URL}/user/token?idToken=${idToken}`, user);

      const profileData ={
        userId: response.data.user.userId,
        displayName: response.data.user.displayName,
        email: response.data.user.email,
        gender: response.data.user.gender || "",
        photoURL: response.data.user.photoURL,
        creationTime: response.data.user.creationTime,
        token: response.data.token,
        loggedIn: true,
      }
      localStorage.setItem("profileData", JSON.stringify(profileData));

      console.log('Profile Data:', profileData);

      return true;
    } catch (error) {
      console.error('Error during sign-in:', error);
      return false;
    }
  };

  // Method to add a new university to the user's profile
  static addUniversity = (university) => {
    const storedProfileData = localStorage.getItem("profileData");
    if (storedProfileData) {
      const profileData = JSON.parse(storedProfileData);
      console.log('Profile Data:', profileData);
      if (!profileData.universities) {
        profileData.universities = [];
      }
      profileData.universities.push(university);
      localStorage.setItem("profileData", JSON.stringify(profileData));
      console.log('University added:', university);
    }
  };

  // Method to handle user sign-out
  static async signOut() {
    try {
      // await axios.post('/api/signout');
      localStorage.removeItem("profileData");
      console.log('User signed out');
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  }

  // Method to get the current user's profile data from localStorage
  static getProfileData() {
    return new Promise((resolve, reject) => {
      try {
        const storedProfileData = localStorage.getItem("profileData");
        if (storedProfileData) {
          const profileData = JSON.parse(storedProfileData);
          console.log('Profile Data:', profileData);
          // Ensure universities is an array, even if empty
          if (!profileData.universities) {
            profileData.universities = [];
          }
          resolve(profileData);
        } else {
          resolve(null); // No profile data available
        }
      } catch (error) {
        reject(error); // Reject with error in case of failure
      }
    })
  };

  static isUserLoggedIn() {
    const storedProfileData = localStorage.getItem("profileData");
    if (storedProfileData) {
      const profileData = JSON.parse(storedProfileData);
      return profileData && profileData.loggedIn;
    }
    return false;
  }

  static getUserToken() {
    const storedProfileData = localStorage.getItem("profileData");
    if (storedProfileData) {
      const profileData = JSON.parse(storedProfileData);
      return profileData && profileData.token;
    }
    return null;
  }

  static getOTP(collegeEmail){
    const response = axios.post(
      `${this.API_BASE_URL}/user/send_otp?collegeEmail=${collegeEmail}`,
      {}, // You need to provide a request body, even if it's an empty object
      {
        headers: {
          'Authorization': `Bearer ${this.getUserToken()}`
        }
      }
    );
    
    console.log(response);
    return response;

  }

  static verifyOTP(batchId, otp){
    const response = axios.post(`${this.API_BASE_URL}/user/verify_otp`, {batchId, otp},{
      headers: {
        'Authorization': `Bearer ${this.getUserToken()}`
      }
    });
    console.log(response);
    return response;
  }

  static getUserUniversities()
  {
      console.log(this.getUserToken());
      try {
        const response = axios.get(`${this.API_BASE_URL}/user/colleges`, {
            headers: {
                'Authorization': `Bearer ${this.getUserToken()}`
            }
        }).then((response) => {
            return response.data;
        });
        console.log('Promise Result:', response);
        return response;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
  }
}

export default UserService;
