import React from 'react'

//Icons
import { SiSemanticscholar } from 'react-icons/si';
import { BiUserVoice } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { LiaUniversitySolid, LiaHandsHelpingSolid } from 'react-icons/lia';
import { MdOutlineAssignment } from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { TbProgressCheck } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';

const WhyUs = () => {
    return (
        <div>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
                        <h2 className="mb-4 title">
                            Why Choose Prep4USA?
                        </h2>
                        <p className="mb-10 subdesc">
                            A Comprehensive Guide to Your Journey of Studying in the USA
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TiTickOutline className='w-8 h-8 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Acceptance & I-20</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Detailed guidance on accepting admission offers and obtaining your I-20 form, the first critical steps to studying in the USA.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AiOutlineSolution className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Visa Application</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            We provide step-by-step instructions on how to successfully apply for your student visa, including required documents and interview tips.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TbProgressCheck className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Financial Planning</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Guidance on managing finances, including tips on tuition payments, scholarships, and setting up a U.S. bank account.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <RiHandCoinLine className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Travel & Accommodation</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Tips on booking flights, arranging airport pick-up, and finding suitable accommodation close to your university.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaUniversitySolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Orientation & Onboarding</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Assistance with understanding university orientation, registering for classes, and getting your student ID and other essentials.
                        </p>
                    </div>
                </div>

                <div className="p-8 border-b">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaHandsHelpingSolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Support & Resources</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Information on how to access student support services, including academic advising, counseling, and health services.
                        </p>
                    </div>

                </div>

                <div className="p-8 border-b sm:border-r lg:border-b-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <MdOutlineAssignment className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Health Insurance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Guidance on selecting the right health insurance plan to ensure you are covered during your stay in the U.S.
                        </p>
                    </div>
                </div>

                <div className="p-8 border-b lg:border-b-0 lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <BiUserVoice className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Pre-Departure Checklist</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            A comprehensive checklist to ensure you have everything in order before you leave, from packing essentials to final paperwork.
                        </p>
                    </div>
                </div>

                <div className="p-8 sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <SiSemanticscholar className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Ongoing Support</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                            Even after you arrive, we provide continuous support to help you navigate your new life in the U.S.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs
