import React, { useEffect, useState } from 'react';
import { FaUser, FaUniversity, FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import Modal from '../components/Modal'; // Import the Modal component
import UserService from '../services/UserService';

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [universities, setUniversities] = useState([]);
  const [university, setUniversity] = useState({ id: '', name: '', uniEmail: '', isVerified: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState('');
  const [verifyIndex, setVerifyIndex] = useState(null);
  const [otp, setOtp] = useState('');
  const [isOTPSent, setIsOTPSent] = useState(false);

  useEffect(() => {
    // Fetch profile data once when the component mounts
    UserService.getProfileData()
      .then((profileData) => {
        setProfile(profileData);
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
      });

    // Fetch universities separately
    UserService.getUserUniversities()
      .then((universityData) => {
        console.log('University Data:', Object.values(universityData));
        setUniversities(Object.values(universityData)||[]);
      })
      .catch((error) => {
        console.error('Error fetching university data:', error);
      });
  }, []); // Adding an empty dependency array ensures this runs only once on mount

  const handleUniversityChange = (e) => {
    const { name, value } = e.target;
    setUniversity({ ...university, [name]: value });
  };

  const addUniversity = () => {
    console.log(university);
    console.log("type of university:", typeof universities);
    if (university.uniEmail) {
      console.log("university is an array:", Array.isArray(universities));
      const newUniversities = [...universities, university];
      setUniversities(newUniversities);
      setUniversity({ id: '', name: '', uniEmail: '', isVerified: false });
    }
  };

  const openVerificationModal = (index) => {
    setEmailToVerify(universities[index].uniEmail);
    setVerifyIndex(index);
    setIsModalOpen(true);
    setIsOTPSent(false); // Reset OTP sent status
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmailToVerify('');
    setVerifyIndex(null);
    setOtp(''); // Reset OTP input
  };

  const getOTP = () => {
    // Simulate sending OTP
    UserService.getOTP(emailToVerify).then((response) => {
      console.log(`Sending OTP to ${emailToVerify}`);
      setIsOTPSent(true);
    }).catch((error) => {
      console.error('Error sending OTP:', error);
    });
  };

  const verifyOTP = () => {
    if (otp && verifyIndex !== null) {
      UserService.verifyOTP(1, otp).then((response) => {
        const updatedUniversities = [...universities];
        updatedUniversities[verifyIndex].isVerified = true;
        setUniversities(updatedUniversities);
      }).catch((error) => {
        console.error('Error verifying OTP:', error);
      });
      closeModal();
    }
  };

  if (!profile || !profile.loggedIn) {
    return (
      <div className="container mx-auto px-4 mt-24">
        <div className="max-w-xl text-center mx-auto">
          <h2 className="text-2xl font-bold text-blue-800 mb-4">Please Log In</h2>
          <p>
            We're excited to help you on your journey to the US! Please log in to view your profile and access all our features.
          </p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="container mx-auto px-4 mt-24">
      <div className="isolate mt-44 mb-36 p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal} title="Verify Email">
            {!isOTPSent ? (
              <div>
                <p>Send OTP to {emailToVerify}?</p>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={getOTP}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>Please enter the OTP sent to your email:</p>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="mt-2 px-4 py-2 border rounded-md w-full"
                  placeholder="Enter OTP"
                />
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={verifyOTP}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            )}
          </Modal>
        )}

        <div className="max-w-xl text-center mx-auto lg:max-w-2xl mb-12">
          <h2 className="mb-6">
            <span className="relative inline-block">
              <span className="relative text-primary title">Your Profile</span>
            </span>
          </h2>
          <div className="flex justify-center mt-5">
            {profile.profilePhoto ? (
              <img src={profile.profilePhoto} alt="Profile" className="rounded-full border-2 border-blue-700 w-24 h-24" />
            ) : (
              <div className="w-24 h-24 rounded-full bg-blue-50 flex items-center justify-center">
                <FaUser className="text-blue-700 w-12 h-12" />
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaUser className="mr-2" /> Name:
              </span>
              <input
                type="text"
                name="name"
                value={profile.displayName}
                readOnly
                className="mt-2 p-2 border border-gray-300 rounded-md w-full"
              />
            </label>
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaEnvelope className="mr-2" /> Email:
              </span>
              <input
                type="email"
                name="email"
                value={profile.email}
                readOnly
                className="mt-2 p-2 border border-gray-300 rounded-md w-full"
              />
            </label>
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-blue-800 mb-4">University Admits</h2>
              <div className="flex flex-wrap gap-4">
                <label className="flex-1">
                  <span className="text-blue-700 font-semibold flex items-center">
                    <FaEnvelope className="mr-2" /> University Email:
                  </span>
                  <input
                    type="email"
                    name="uniEmail"
                    value={university.uniEmail}
                    onChange={handleUniversityChange}
                    className="mt-2 p-2 border border-gray-300 rounded-md w-full"
                  />
                </label>
              </div>
              <button
                onClick={addUniversity}
                className="mt-4 px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800 transition"
              >
                Add University
              </button>
              <ul className="mt-4 space-y-4">
                {universities.length>0 && universities.map((uni, index) => (
                  <li key={index} className="p-4 border border-gray-200 rounded-md flex items-center">
                    <FaUniversity className="text-blue-700 mr-4" />
                    <div className="flex-1">
                      <p className="text-lg font-semibold">{uni.name}</p>
                      <p className="text-gray-600">{uni.uniEmail}</p>
                    </div>
                    <div className="flex items-center space-x-4 ml-4">
                      {uni.isVerified ? (
                        <FaCheckCircle className="text-green-500" />
                      ) : (
                        <button
                          onClick={() => openVerificationModal(index)}
                          className="px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
                        >
                          Verify
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
