import React from "react";
import { useNavigate } from "react-router-dom";

// Icons
import { GiPayMoney } from 'react-icons/gi';
import { FaUniversity } from 'react-icons/fa';
import { BsBriefcaseFill } from 'react-icons/bs';
import { GiSkills } from 'react-icons/gi';
import { MdModelTraining } from 'react-icons/md';
import { PiStudentFill } from 'react-icons/pi';

const Features = () => {
    const navigate = useNavigate();

    // Function to handle navigation to checklist
    const redirectToChecklist = () => {
        navigate('/checklist');
    };

    return (
        <div>
            <div className="max-w-xl text-center mx-auto lg:max-w-2xl mb-12">
                <h2 className="mb-6">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="903f4a9e-7ac3-441c-9613-04c15fcc0a14"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#903f4a9e-7ac3-441c-9613-04c15fcc0a14)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative text-primary title">Features</span>
                    </span>
                </h2>
                <p className="mb-10 subdesc">
                    Prep4USA is designed to empower students with the tools and guidance they need to succeed in their journey to study in the USA. Our key features include:
                </p>
            </div>
            <div className="flex flex-wrap justify-center mx-auto lg:max-w-screen-xl">
                <FeatureCard
                    title="University Insights"
                    details="Access in-depth information about top universities to make informed decisions."
                    icon={
                        <FaUniversity className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
                <FeatureCard
                    title="Step-by-Step Guidance"
                    details="Receive comprehensive guidance from application to arrival."
                    icon={
                        <GiSkills className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
                <FeatureCard
                    title="Financial Planning"
                    details="Explore financial resources, including scholarships and funding options."
                    icon={
                        <GiPayMoney className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
                <FeatureCard
                    title="Visa Assistance"
                    details="Expert advice on navigating the complex visa application process."
                    icon={
                        <MdModelTraining className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
                <FeatureCard
                    title="Cultural Orientation"
                    details="Prepare for life in the USA with tips on adapting to American culture."
                    icon={
                        <BsBriefcaseFill className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
                <FeatureCard
                    title="Networking Opportunities"
                    details="Connect with fellow students and alumni to build a support network."
                    icon={
                        <PiStudentFill className="text-white w-auto h-32" />
                    }
                    redirectToChecklist={redirectToChecklist}
                />
            </div>
        </div>
    );
};

export default Features;

const FeatureCard = ({ icon, title, details, redirectToChecklist }) => {
    return (
        <div className="mt-10 px-2 w-1/6 lg:w-1/6">
            <div
                className="relative mb-2 rounded-[20px] bg-white p-5 shadow-md md:px-2 xl:px-5 transition-all duration-300 transform hover:bg-blue-100 hover:scale-105 hover:cursor-pointer"
                style={{ minHeight: '320px' }} // Increase the min height to fit more content
                onClick={redirectToChecklist}
            >
                <div className="mb-6 flex h-[60px] w-[60px] p-3 items-center justify-center rounded-2xl bg-primary">
                    {icon}
                </div>
                <h4 className="text-sm sm:text-base mb-2 font-semibold text-primary">{title}</h4>
                <p className="text-base font-medium">{details}</p>
            </div>
        </div>
    );
};
