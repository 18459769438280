import axios from 'axios';

class TaskService {
    // Using 'this' to access instance properties
    
    API_BASE_URL = 'https://stagingapi.prep4usa.com:8444/pfu-rest-api/api/v1';
    // API_BASE_URL = 'http://localhost:8080/api/v1';

    // Update Task
    updateTask = async (task) => {
        try {
            await axios.post(`${this.API_BASE_URL}/task`, {
                taskId: task.taskId,
                parentId: task.parentId,
                taskName: task.taskName,
                iconUrl: task.iconUrl || '',
                ytUrl: task.videoUrl,
                blog: task.blog,  // Set FAQ as blog
                faq: task.faq,        // FAQ field
                taskStage: task.taskStage || 1,
            });
            console.log('Task updated successfully');
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    // Get All Tasks
    getAllTasks = async () => {
        try {
            const response = await axios.get(`${this.API_BASE_URL}/tasks/all`);
            console.log('Response from API:', response.data);
            this.taskRespose = response.data; // Cache the tasks
            console.log('Tasks fetched successfully');
        } catch (error) {
            console.error('Error fetching tasks:', error);
            this.tasks = []; // Set to an empty array if there's an error
        }
        return this.taskRespose;
    };

    // Get Parent Tasks
    processTasks = async () => {
        const tasks = await this.getAllTasks();
        console.log(tasks);
        try {
            const parentTasks = []; // Array to store parent tasks
            const steps = {}; // Dictionary to store steps with parent_id as key
            const subSteps = {}; // Dictionary to store subSteps with parent_id as key
            
            for (const task of tasks) { // Iterate over each task
                if (task.taskType === 1) { // Check if the task type is not '1'
                    parentTasks.push(task); // Add task to the parent array
                } else if (task.taskType === 2) { // Check if the task type is '1'
                    if (!steps[task.parentId]) {
                        steps[task.parentId] = []; // Initialize an array for this parent_id if it doesn't exist
                    }
                    steps[task.parentId].push(task); // Add task to the steps dictionary under the correct parent_id
                } else {
                    if (!subSteps[task.parentId]) {
                        subSteps[task.parentId] = []; // Initialize an array for this parent_id if it doesn't exist
                    }
                    subSteps[task.parentId].push(task); // Add task to the subSteps dictionary under the correct parent_id
                }
            }
            console.log("Parent Tasks:", parentTasks);
            console.log("Steps:", steps);
            console.log("SubSteps:", subSteps);
            return { parentTasks, steps, subSteps }; // Return the arrays of parent tasks and dictionaries for steps and subSteps
    
        } catch (error) {
            console.error('Error processing tasks:', error);
            return { parent: [], steps: {}, subSteps: {} }; // Return empty objects in case of an error
        }
    };
    

}

export default TaskService;
