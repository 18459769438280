// import React from 'react'

// // Icons
// import { FaUserGraduate } from 'react-icons/fa';  // Updated for universities
// import { FaTasks } from 'react-icons/fa';         // Updated for checklist steps
// import { IoLibraryOutline } from 'react-icons/io5'; // Updated for resources
// import { FaChartLine } from 'react-icons/fa';     // Updated for tracking journey

// const Stats = () => {
//     return (
//         <div className="mx-auto md:max-w-xl lg:max-w-screen-xl grid gap-8 grid-cols-2 lg:grid-cols-4">
//             <div className="text-center">
//                 <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-50 shadow-md">
//                     <FaUserGraduate className="w-8 h-8 sm:w-10 sm:h-10 text-blue-700" />
//                 </div>
//                 <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-700">200+</h6>
//                 <p className="text-sm mb-2 font-semibold text-gray-700">Universities</p>
//             </div>
//             <div className="text-center">
//                 <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-100 shadow-md">
//                     <FaTasks className="w-8 h-8 sm:w-10 sm:h-10 text-blue-500" />
//                 </div>
//                 <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-500">60+</h6>
//                 <p className="text-sm mb-2 font-semibold text-gray-700">Checklist Steps</p>
//             </div>
//             <div className="text-center">
//                 <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-200 shadow-md">
//                     <IoLibraryOutline className="w-8 h-8 sm:w-10 sm:h-10 text-blue-400" />
//                 </div>
//                 <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-400">30+</h6>
//                 <p className="text-sm mb-2 font-semibold text-gray-700">Resources</p>
//             </div>
//             <div className="text-center">
//                 <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-300 shadow-md">
//                     <FaChartLine className="w-8 h-8 sm:w-10 sm:h-10 text-blue-300" />
//                 </div>
//                 <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-300">100%</h6>
//                 <p className="text-sm mb-2 font-semibold text-gray-700">Track Your Journey</p>
//             </div>
//         </div>
//     )
// }

// export default Stats;

import React, { useEffect, useState } from 'react';
import { FaUniversity, FaListAlt, FaRegCircle, FaPlaneDeparture } from 'react-icons/fa';
import { IoChatbubblesOutline } from 'react-icons/io5';
import IndiaFlag from '../assets/india-flag.png'; // Assuming you have India and USA flag assets
import USAFlag from '../assets/usa-flag.png';

const Stats = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // Progress bar and plane animation that loops
        const interval = setInterval(() => {
            setProgress(prev => (prev < 100 ? prev + 1 : 0)); // Reset progress after reaching 100%
        }, 80); // Controls the speed of the progress (80ms for 1% progress)

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative">
            {/* India and USA Flags with Progress Bar */}
            <div className="flex justify-between items-center mb-10 relative">
                {/* India Flag */}
                <div className="flex flex-col items-center">
                    <div className="w-16 h-16 rounded-full bg-blue-50 shadow-md flex justify-center items-center">
                        <img src={IndiaFlag} alt="India" className="w-10 h-10" />
                    </div>
                    <p className="text-blue-700 text-lg font-semibold mt-2">India</p>
                </div>

                {/* Progress Bar with Plane */}
                <div className="progress-bar-container relative flex items-center w-3/4 mx-auto">
                    <div className="w-full h-6 bg-gray-200 rounded-full relative">
                        <div
                            className="bg-blue-500 h-full rounded-full transition-all duration-300"
                            style={{ width: `${progress}%` }}
                        ></div>
                        <span className="absolute inset-0 flex items-center justify-center text-black font-bold">
                            {`Your Progress: ${progress}%`}
                        </span>
                    </div>

                    {/* Plane Animation */}
                    <FaPlaneDeparture
                        className="plane-icon absolute top-[-30px] text-blue-500"
                        style={{ left: `${progress}%`, transition: 'left 0.08s ease-out' }} // Plane moves smoothly with progress
                    />
                </div>

                {/* USA Flag */}
                <div className="flex flex-col items-center">
                    <div className="w-16 h-16 rounded-full bg-blue-50 shadow-md flex justify-center items-center">
                        <img src={USAFlag} alt="USA" className="w-10 h-10" />
                    </div>
                    <p className="text-blue-700 text-lg font-semibold mt-2">USA</p>
                </div>
            </div>

            {/* Stats Section */}
            <div className="mx-auto md:max-w-xl lg:max-w-screen-xl grid grid-cols-4 gap-x-6 mt-8">
                <div className="text-center">
                    <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-50 shadow-md">
                        <FaUniversity className="w-8 h-8 sm:w-10 sm:h-10 text-blue-700" />
                    </div>
                    <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-700">200+</h6>
                    <p className="text-sm mb-2 font-semibold text-gray-700">Universities</p>
                </div>
                <div className="text-center">
                    <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-100 shadow-md">
                        <FaListAlt className="w-8 h-8 sm:w-10 sm:h-10 text-blue-500" />
                    </div>
                    <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-500">60+</h6>
                    <p className="text-sm mb-2 font-semibold text-gray-700">Checklist Steps</p>
                </div>
                <div className="text-center">
                    <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-200 shadow-md">
                        <IoChatbubblesOutline className="w-8 h-8 sm:w-10 sm:h-10 text-blue-400" />
                    </div>
                    <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-400">30+</h6>
                    <p className="text-sm mb-2 font-semibold text-gray-700">Resources</p>
                </div>
                <div className="text-center">
                    <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-blue-300 shadow-md">
                        <FaRegCircle className="w-8 h-8 sm:w-10 sm:h-10 text-blue-300" />
                    </div>
                    <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-blue-300">100%</h6>
                    <p className="text-sm mb-2 font-semibold text-gray-700">Track Your Journey</p>
                </div>
            </div>

            {/* Styles for the plane animation and progress bar */}
            <style jsx>{`
                .plane-icon {
                    font-size: 2.5rem;
                }

                .progress-bar-container {
                    height: 8px;
                    border-radius: 4px;
                    background-color: #e2e8f0;
                    position: relative;
                }

                .progress-bar {
                    background-color: #007bff;
                    height: 100%;
                    width: 0%;
                    transition: width 8s ease;
                }

                .progress-bar.animate {
                    width: 100%;
                }
            `}</style>
        </div>
    );
};

export default Stats;