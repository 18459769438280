import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css'

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Testimonial = () => {
    return (
        <div className="space-y-8" id='testimonials'>
            <div className="text-center">
                <h2 className="mb-6 title">
                    Our Student Reviews
                </h2>
                <p className="subdesc">
                    Trusted by Thousands of Students.
                </p>
            </div>
            <section className="relative isolate overflow-hidden bg-white">
                <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

                <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                    <figure className="max-w-screen-md mx-auto">
                        <svg className="h-12 mx-auto mb-3 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                        </svg>

                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide className="p-6 pb-16">
                                <div className="px-12">
                                    <blockquote>
                                        <p className="text-sm md:text-lg font-medium text-gray-800">“The step-by-step checklist made the whole process so easy to follow. I knew exactly what to do at each stage, and the support was fantastic!”</p>
                                    </blockquote>
                                    <figcaption className="mt-6 font-medium text-gray-800 text-sm md:text-base">
                                        Rajesh Sharma
                                    </figcaption>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="p-6 pb-16">
                                <div className="px-12">
                                    <blockquote>
                                        <p className="text-sm md:text-lg font-medium text-gray-800">“Every step was clearly explained, and I felt confident moving forward. The checklist really helped me stay on track.”</p>
                                    </blockquote>
                                    <figcaption className="mt-6 font-medium text-gray-800 text-sm md:text-base">
                                        Priya Singh
                                    </figcaption>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="p-6 pb-16">
                                <div className="px-12">
                                    <blockquote>
                                        <p className="text-sm md:text-lg font-medium text-gray-800">“I couldn’t have done it without the step-by-step guidance. It was like having a mentor by my side the whole time.”</p>
                                    </blockquote>
                                    <figcaption className="mt-6 font-medium text-gray-800 text-sm md:text-base">
                                        Anil Patel
                                    </figcaption>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="p-6 pb-16">
                                <div className="px-12">
                                    <blockquote>
                                        <p className="text-sm md:text-lg font-medium text-gray-800">“The checklist covered everything I needed. It was incredibly helpful to have such detailed steps to follow.”</p>
                                    </blockquote>
                                    <figcaption className="mt-6 font-medium text-gray-800 text-sm md:text-base">
                                        Sanya Verma
                                    </figcaption>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </figure>
                </div>
            </section>
        </div>
    );
};

export default Testimonial;
